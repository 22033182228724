import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectOnboarding = (state: RootState) => state.onboarding;

const selectGender = createSelector(selectOnboarding, (onboarding) => onboarding.gender);
const selectAge = createSelector(selectOnboarding, (onboarding) => onboarding.age);
const selectCurrentWeight = createSelector(selectOnboarding, (onboarding) => onboarding.current_weight);
const selectTargetWeight = createSelector(selectOnboarding, (onboarding) => onboarding.target_weight);
const selectFitnessWalking = createSelector(selectOnboarding, (onboarding) => onboarding.fitness_walking);
const selectDefaultActivity = createSelector(selectOnboarding, (onboarding) => onboarding.default_activity);
const selectGoal = createSelector(selectOnboarding, (onboarding) => onboarding.goal);
const selectMeasureSystem = createSelector(selectOnboarding, (onboarding) => onboarding.measureSystem);
const selectOnboardingScreensList = createSelector(selectOnboarding, (onboarding) => onboarding.onboardingScreensList);
const selectTall = createSelector(selectOnboarding, (onboarding) => onboarding.tall);
const selectUrlParams = createSelector(selectOnboarding, (onboarding) => onboarding.urlParams);
const selectAgeRange = createSelector(selectOnboarding, (onboarding) => onboarding.age_range);
const selectCrowded = createSelector(selectOnboarding, (onboarding) => onboarding.crowded);
const selectSuitableTrails = createSelector(selectOnboarding, (onboarding) => onboarding.suitable_trails);
const selectStressFactors = createSelector(selectOnboarding, (onboarding) => onboarding.stress_factors);
const selectSleepProblems = createSelector(selectOnboarding, (onboarding) => onboarding.sleep_problems);
const selectCurrentMood = createSelector(selectOnboarding, (onboarding) => onboarding.current_mood);
const selectActivityLevel = createSelector(selectOnboarding, (onboarding) => onboarding.activityLevel);
const selectCurrentBody = createSelector(selectOnboarding, (onboarding) => onboarding.current_body);
const selectPush = createSelector(selectOnboarding, (onboarding) => onboarding.push);
const selectReasons = createSelector(selectOnboarding, (onboarding) => onboarding.reasons);
const selectHike = createSelector(selectOnboarding, (onboarding) => onboarding.hike);
const selectTrailDifficulty = createSelector(selectOnboarding, (onboarding) => onboarding.trailDifficulty);
const selectWaterfall = createSelector(selectOnboarding, (onboarding) => onboarding.waterfall);
const selectBeforehand = createSelector(selectOnboarding, (onboarding) => onboarding.beforehand);
const selectTrailDistance = createSelector(selectOnboarding, (onboarding) => onboarding.trailDistance);
const selectBmiGroup = createSelector(selectOnboarding, (onboarding) => onboarding.bmi_group);
const selectSpecialActivity = createSelector(selectOnboarding, (onboarding) => onboarding.special_activity);
const selectHealth = createSelector(selectOnboarding, (onboarding) => onboarding.health);
const selectNutritionPreferences = createSelector(selectOnboarding, (onboarding) => onboarding.nutrition_preferences);
const selectFitnessLevel = createSelector(selectOnboarding, (onboarding) => onboarding.fitnessLevel);
const selectState = createSelector(selectOnboarding, (onboarding) => onboarding.state);

export {
    selectState,
    selectOnboarding,
    selectGender,
    selectCurrentWeight,
    selectTargetWeight,
    selectAge,
    selectGoal,
    selectMeasureSystem,
    selectOnboardingScreensList,
    selectTall,
    selectUrlParams,
    selectAgeRange,
    selectStressFactors,
    selectSleepProblems,
    selectCurrentMood,
    selectActivityLevel,
    selectCurrentBody,
    selectDefaultActivity,
    selectFitnessWalking,
    selectCrowded,
    selectSuitableTrails,
    selectPush,
    selectReasons,
    selectHike,
    selectTrailDifficulty,
    selectWaterfall,
    selectBeforehand,
    selectTrailDistance,
    selectBmiGroup,
    selectSpecialActivity,
    selectHealth,
    selectNutritionPreferences,
    selectFitnessLevel,
};
